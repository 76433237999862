<template> 
    <div>
        <div class="text-xs-right">
            <v-btn small class="animate__animated animate__flash animate__delay-1s white--text" round color="teal"  :to="'/njangi/groups/contribute/'+njangi_id" >
                <v-icon left class="fas fa-plus " > </v-icon>
                <span> Contribute Now</span>
            </v-btn>
        </div>
        <v-layout row wrap class="white elevation-6" style="border-radius: 10px">            
            <v-layout row wrap class="white--text primary darken-2 pa-2" style="border: 1px solid grey">
                <v-flex xs12>
                    <h3>{{ njangi_group_name }} </h3>
                </v-flex>
            </v-layout>
            <v-card-text class="body-1 pa-2 mb-0" style="font-size: 12px; padding: 5px">
                <v-layout row wrap class="">
                    <v-flex xs12 class="py-0 my-0">
                        <span class="caption"> <b>Njangi Description:</b> <p class="dark--text caption pa-0 ma-0 " blue v-html="njangi_group_description"></p></span>
                    </v-flex>
                    <v-flex xs12 md6 class="pt-0 mt-0">
                        <span class="caption"> <b>Njangi Admin Contact:</b> {{ njangi_contact }} </span>
                    </v-flex>
                    <v-flex xs12 md6 class="pt-0 mt-0">
                        <span class="caption"> <b>Contributions:</b>  {{ contribution_amount }} F CFA per person {{ collecting_cycle }} </span>
                    </v-flex>
                    <v-flex v-if="!collection_activated" xs12 md6 class="pt-0 mt-0">
                        <span class="caption"> <b>Start Date:</b> {{ start_date }} </span>
                    </v-flex>
                </v-layout>
                <v-spacer></v-spacer>
            </v-card-text>
            <v-flex xs12 text-xs-left class="pa-2">
                <h3 v-if="collecting_cycle === 'Daily'" ><u>Today</u></h3>
                <h3 v-if="collecting_cycle === 'Weekly'" ><u>This Week</u></h3>
                <h3 v-if="collecting_cycle === 'Monthly'" ><u>This Month</u></h3>
                <p class="pa-0 ma-1"><b>Collecting:</b> {{ current_collector }}</p>
                <p class="pa-0 ma-1"><b>No Contributed:</b> {{ no_contributed }}/{{ no_members }}</p>
                <p class="pa-0 ma-1"><b>Amount Contributed:</b> {{ contributed_amount }} F CFA/ {{ expected_amount }} F CFA</p>
                <p v-if="collection_activated" class="pa-0 ma-1"><b>Collection Date:</b> {{ collection_date }}</p>
            </v-flex>
        </v-layout>

        <br>
        <h2 v-if="njangiRequestedTransactions.length !== 0" class="mt-3 dark--text font-weight-bold text-xs-left"><u>Requested Transactions</u></h2>
        <div v-if="njangiRequestedTransactions.length !== 0" class="white elevation-6 pa-2" style="max-height: 50px; overflow: auto; border-radius: 7px"> 
            <div color="white" style="" v-for="item in njangiRequestedTransactions" :key="item.id">
                <v-card-text class="pa-1" style="font-size: 12px;">
                    <v-layout row wrap v-if="!item.cancelled || item.withdrawal || (!item.withdrawal && !item.confirmed)">
                        <v-flex xs8 text-xs-left>
                            <div v-if="item.withdrawal" > <!-- this will be an interrupt-->
                                <span class="red--text font-weight-bold">Interrupt:</span><span> {{item.amount}} FCFA</span>
                            </div>
                            <div v-else>
                                <span class="dark--text font-weight-bold">Deposit:</span><span> {{item.amount}} FCFA</span>
                            </div>
                            <div v-if="!item.confirmed && !item.cancelled" xs8 text-xs-left>
                                <span class="font-weight-bold ">Status:</span><span class="primary--text font-weight-bold "> Pending </span>
                            </div>
                            <div v-if="item.confirmed" xs8 text-xs-left>
                                <span class="font-weight-bold ">Status:</span><span class="success--text font-weight-bold "> Confirmed </span>
                            </div>
                        </v-flex>
                        <v-flex xs4 text-xs-right>
                            <span class="dark--text font-weight-bold">Date:</span><span> {{item.get_date_str}}</span>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            <v-divider></v-divider>
            </div>
        </div>

        <br>        
        <v-layout row wrap class="white elevation-6" style="border-radius: 10px">            
            <v-layout row wrap class="white--text primary darken-2 pa-2" style="border: 1px solid grey">
                <v-flex xs12>
                    <h3>Njangi Members (Contribution History) </h3>
                </v-flex>
            </v-layout>
            <v-layout row wrap class="text-xs-center" style="border: 1px solid grey">
                <v-flex xs12>
                    <label v-if="collecting_cycle === 'Daily'" for="date_history"><b>Choose Date: </b></label>
                    <label v-if="collecting_cycle === 'Weekly'" for="date_history"><b>Choose Week: </b></label>
                    <label v-if="collecting_cycle === 'Monthly'" for="date_history"><b>Choose Month: </b></label>
                    
                    <input @change="getContribHistory()" v-if="collecting_cycle === 'Daily'" type="date" v-model="date_history" id="date_history"/>
                    <input @change="getContribHistory()" v-else-if="collecting_cycle === 'Weekly'" type="week" v-model="date_history" id="date_history"/>
                    <input @change="getContribHistory()" v-else-if="collecting_cycle === 'Monthly'" type="month" v-model="date_history" id="date_history"/>
                </v-flex>
                <v-flex xs12 class="text-xs-left pa-1">
                    <hr>
                    <small class="pa-0 ma-1"><b>Amount Contributed:</b> {{ history_contributed_amount }} F CFA/ {{ history_expected_amount }} F CFA</small>
                    <hr>
                    <ul v-for="item in njangi_members" :key="item.id" >
                        <li class="text-xs-left" v-if="item.contributed">{{ item.member_name }} (<i class="fa-solid fa-check teal--text"></i>Contributed)</li>
                        <li class="text-xs-left" v-else>{{ item.member_name }} (<i class="fa-solid fa-xmark red--text"></i> Didn't contribute)</li>
                    </ul>                
                </v-flex>
            </v-layout>
        </v-layout>
    </div>
</template>

<script>
    import axios from 'axios'
    import 'animate.css'

    export default {

        data() {
            return {
                dialog: false,
                njangi_id: "",
                njangi_members: [],
                njangi_group_name: '',
                njangi_contact: '',
                njangi_group_description: '',
                contribution_amount: '',
                collecting_interval: '',
                collecting_cycle: '',
                no_contributed: null,
                no_members: null,
                njangiRequestedTransactions: [],
                date_history: "",
                collection_date: "",
                start_date: "",
                collection_activated: "",
                contributed_amount: "",
                expected_amount: "",
                current_collector: "",
                history_contributed_amount: "",
                history_expected_amount: "",

                menuItems: [
                    {
                        id: '1',
                        title: 'Membership Info.',
                        link: '/njangi/groups/1/group_info',
                    },
                    {
                        id: '2',
                        title: 'Invite Others',
                        link: '/njangi/groups/1/members',
                    },
                    {
                        id: '3',
                        title: 'View Complaints',
                        link: '/njangi/groups/complaints/',
                    },
                    {
                        id: '4',
                        title: 'Raise Complaints',
                        link: '/njangi/groups/1/statistics',
                    },
                    {
                        id: '5',
                        title: 'Exit Group',
                        link: '/njangi/groups/1/history',
                    },
                ],
            }
        },
        mounted(){
            // executes these after the page has been mounted
            document.title = "PettyCash | Njangi Group"
            this.getNjangiGroupSetting()
            this.getClientRequestedNjangiTransactions()
        },

        methods:{
            async getClientRequestedNjangiTransactions(){
                this.$store.commit('setIsLoading', true)
                
                const group_id = this.$route.params.key  // get campaign category from url and send request to server
                await axios
                    .get('/api/v1/njangi/get/user/group/'+group_id+'/requested_transactions/')
                    .then(response => {
                        this.njangiRequestedTransactions = response.data  // get the data and fill into campaigns
                        console.log(this.njangiRequestedTransactions)
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },

            async getContribHistory(){
                this.$store.commit('setIsLoading', true)
                const group_id = this.$route.params.key  // get group id from url and send request to server
                await axios
                    .get('/api/v1/njangi/get/user/group/members/'+group_id+'/'+this.date_history+'/')
                    .then(response => {
                        console.log(response.data)
                        this.njangi_members = response.data.group_members_contrib_info
                        this.history_contributed_amount = response.data.total_contributed
                        this.history_expected_amount = response.data.expected_contribution
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },

            async getNjangiGroupSetting(){
                this.$store.commit('setIsLoading', true)
                const group_id = this.$route.params.key  // get group id from url and send request to server
            
                await axios
                    .get('/api/v1/njangi/get/user/group/setting/detail/'+group_id+'/')
                    .then(response => {
                        this.njangi_id = response.data[1].njangi_group_id
                        this.njangi_group_name = response.data[1].njangi_group_name
                        this.njangi_group_description = response.data[1].njangi_group_description
                        this.njangi_contact = response.data[1].njangi_contact
                        this.contribution_amount = response.data[1].contribution_amount
                        this.collecting_cycle = response.data[1].collecting_cycle
                        this.no_contributed = response.data[1].get_no_contribution_members
                        this.no_members = response.data[1].get_no_members
                        this.contributed_amount = response.data[1].contributed_amount
                        this.expected_amount = response.data[1].expected_amount
                        this.current_collector = response.data[1].get_current_collector
                        this.date_history = response.data[1].get_date_history
                        this.collection_date = response.data[1].get_collection_date
                        this.start_date = response.data[1].get_start_date
                        this.collection_activated = response.data[1].collection_activated
                        this.njangi_members = response.data[2].group_members_contrib_info
                        this.history_contributed_amount = response.data[2].total_contributed
                        this.history_expected_amount = response.data[2].expected_contribution
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
        },

    }
</script>
